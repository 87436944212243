import logo from "./logo.svg";
import React, { useEffect, useRef, useState } from "react";
import "./App.css";
import ReCAPTCHA from "react-google-recaptcha";
import { InlineShareButtons } from "sharethis-reactjs";

function App() {
  const [text, setText] = useState("");
  const [postContent, setPostContent] = useState("");
  const [loading, setLoading] = useState(false);
  const [readyToCopy, setReadyToCopy] = useState(false);
  const [readyForSubmit, setReadyForSubmit] = useState(false);
  const [textCopied, setTextCopied] = useState(false);
  const [showShare, setShowShare] = useState(false);
  const reCaptchaRef = useRef(null);
  const [showCaptcha, setShowCaptcha] = useState(false);

  const onCaptchaEntered = () => {
    console.log("XXXXXx", reCaptchaRef.current?.getValue());
    setLoading(true);
    setShowCaptcha(false);

    const data = {
      reCaptcha: reCaptchaRef.current?.getValue(),
      text: document.getElementById("story").value,
    };
    fetch("/api/posts/english911", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (!response.ok) {
          // If the response is not OK (e.g., status code 4xx or 5xx), throw an error
          throw new Error("Network response was not ok");
        }
        return response.text(); // Parse the response body as JSON
      })
      .then((data) => {
        document.getElementById("story").value = data;
        console.log("Success:", data); // Handle the data in case of a successful response
        setLoading(false);
        setReadyToCopy(true);
      })
      .catch((error) => {
        console.error("Error:", error); // Handle any errors that occurred during the fetch
      })
      .finally(() => {
        if (reCaptchaRef && reCaptchaRef.current) {
          reCaptchaRef.current.reset();
        }
      });
  };

  useEffect(() => {
    const textarea = document.getElementById("story");
    textarea.style.height = "auto"; // Reset the height to auto to calculate the actual size
    textarea.style.height = textarea.scrollHeight + "px";
  }, []);

  const setNewText = (text) => {
    //setPostContent(text);
    const textarea = document.getElementById("story");
    textarea.value = text;
    updateTextareaSize();
  };

  const updateTextareaSize = (e) => {
    const textarea = document.getElementById("story");
    textarea.style.height = "auto"; // Reset the height to auto to calculate the actual size
    textarea.style.height = textarea.scrollHeight + "px"; //

    if (textarea.value.length > 0) {
      setReadyForSubmit(true);
    } else {
      setReadyForSubmit(false);
    }
    // const lines = textarea.value.split("\n");
    // const maxWidth = lines.reduce((maxWidth, line) => {
    //   const lineWidth = Math.round(line.length * 8.7); // Adjust the multiplier according to your font size and style
    //   return Math.max(maxWidth, lineWidth);
    // }, 0);
    // textarea.style.width = Math.max(maxWidth) + "px";
  };

  return (
    <div className="App">
      <div className="textAreaWrapper">
        <label htmlFor="story" className="topActionBar">
          {showShare ? (
            <>
              <InlineShareButtons
                config={{
                  alignment: "left", // alignment of buttons (left, center, right)
                  color: "social", // set the color of buttons (social, white)
                  enabled: true, // show/hide buttons (true, false)
                  font_size: 16, // font size for the buttons
                  labels: "cta", // button labels (cta, counts, null)
                  language: "en", // which language to use (see LANGUAGES)
                  networks: [
                    // which networks to include (see SHARING NETWORKS)
                    "sharethis",
                    "twitter",
                    "facebook",
                  ],
                  padding: 12, // padding within buttons (INTEGER)
                  radius: 4, // the corner radius on each button (INTEGER)
                  show_total: true,
                  size: 30, // the size of each button (INTEGER)

                  // OPTIONAL PARAMETERS

                  url: "https://english911.com/", // (defaults to current url)
                  image: "https://english911.com/911-emergency-call.jpg", // (defaults to og:image or twitter:image)
                  title:
                    "English 911: Your go-to solution for flawless text editing. Trust us to refine your writing with precision and expertise", // (defaults to og:title or twitter:title)
                  description:
                    "🌟 Elevate your writing with English 911! 📝 Say goodbye to typos and grammar mistakes. Our expert editors ensure your text shines. Try it now! #English911 #Writing #Editing #Grammar #Language #Communication", // (defaults to og:description or twitter:description)
                  message:
                    "🌟 Elevate your writing with English 911! 📝 Say goodbye to typos and grammar mistakes. Our expert editors ensure your text shines. Try it now! #English911 #Writing #Editing #Grammar #Language #Communication", // (only for email sharing)
                  subject:
                    "English 911: Your go-to solution for flawless text editing.", // (only for email sharing)
                }}
              />
            </>
          ) : (
            <>
              {" "}
              <svg
                fill="currentColor"
                style={{
                  margin: "-7px 6px -3px -9px",
                }}
                height="23px"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 1050.68 719.69"
              >
                <defs>
                  <style>{`
              .cls-1{fill:#fff;stroke:#fff;}
              .cls-1,.cls-2{stroke-linecap:round;stroke-miterlimit:10;stroke-width:38px;}
              .cls-2{
                fill:none;
                stroke:currentColor;
                opacity: 0.3;
              }
              
              `}</style>
                </defs>
                <path
                  d="M1041.89,470.41l-1.89-1.75c-1.15-1.06-2.31-2.13-3.42-3.24L856.87,285.76a16.33,16.33,0,0,0-1.23-1.12,80.46,80.46,0,0,0-30.45-16v-44a19.29,19.29,0,0,0-19.28-19.29H718.5a19.29,19.29,0,0,0-19.28,19.29v41.12H327.49a57,57,0,0,0-57,57V664.09a19.29,19.29,0,0,0,19.29,19.28h50.36a86.23,86.23,0,0,0,168.11,0H838.74a86.24,86.24,0,0,0,168.14-.12,66,66,0,0,0,63.64-65.83V536.57A91.06,91.06,0,0,0,1041.89,470.41ZM737.79,244h48.84v21.84H737.79V244ZM424.22,711.78a47.7,47.7,0,1,1,47.7-47.69A47.75,47.75,0,0,1,424.22,711.78ZM758.36,454a17,17,0,0,1-17-17V346a9.46,9.46,0,0,1,9.46-9.45h60.71a22.32,22.32,0,0,1,15.88,6.57l92.76,92.77a10.59,10.59,0,0,1-7.5,18.09ZM922.79,711.78a47.7,47.7,0,1,1,47.7-47.69A47.75,47.75,0,0,1,922.79,711.78Z"
                  transform="translate(-19.84 -30.66)"
                />
                <line
                  className="cls-1"
                  x1="405.57"
                  y1="316.84"
                  x2="404.74"
                  y2="463.82"
                />
                <line
                  className="cls-1"
                  x1="330.88"
                  y1="388.46"
                  x2="477.87"
                  y2="388.46"
                />
                <line
                  className="cls-2"
                  x1="656.76"
                  y1="149.28"
                  x2="566.59"
                  y2="58.43"
                />
                <line
                  className="cls-2"
                  x1="831.91"
                  y1="150.27"
                  x2="923.09"
                  y2="60.44"
                />
                <line
                  className="cls-2"
                  x1="739.66"
                  y1="130.95"
                  x2="740.29"
                  y2="19"
                />
                <line
                  className="cls-2"
                  x1="201.96"
                  y1="258.33"
                  x2="95.83"
                  y2="258.52"
                />
                <line
                  className="cls-2"
                  x1="175.51"
                  y1="390.21"
                  x2="29.64"
                  y2="390.48"
                />
                <line
                  className="cls-2"
                  x1="185.77"
                  y1="513.06"
                  x2="103.57"
                  y2="513.21"
                />
                <line
                  className="cls-2"
                  x1="187.42"
                  y1="633.31"
                  x2="19"
                  y2="633.62"
                />
              </svg>
              English 911
            </>
          )}
          {!showShare && (
            <div
              className="shareButton"
              style={{
                display: "inline-block",
                position: "absolute",
                cursor: "pointer",
                right: "0",
                padding: "0px 11px",
                borderRadius: "5px",
              }}
              onClick={() => {
                setShowShare(!showShare);
              }}
            >
              Share
              <svg
                fill="currentColor"
                style={{
                  marginBottom: "-3px",
                  marginLeft: "5px",
                  display: "inline-block",
                }}
                height="18px"
                version="1.1"
                id="Layer_1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 458.624 458.624"
              >
                <g>
                  <g>
                    <path
                      d="M339.588,314.529c-14.215,0-27.456,4.133-38.621,11.239l-112.682-78.67c1.809-6.315,2.798-12.976,2.798-19.871
			c0-6.896-0.989-13.557-2.798-19.871l109.64-76.547c11.764,8.356,26.133,13.286,41.662,13.286c39.79,0,72.047-32.257,72.047-72.047
			C411.634,32.258,379.378,0,339.588,0c-39.79,0-72.047,32.257-72.047,72.047c0,5.255,0.578,10.373,1.646,15.308l-112.424,78.491
			c-10.974-6.759-23.892-10.666-37.727-10.666c-39.79,0-72.047,32.257-72.047,72.047s32.256,72.047,72.047,72.047
			c13.834,0,26.753-3.907,37.727-10.666l113.292,79.097c-1.629,6.017-2.514,12.34-2.514,18.872c0,39.79,32.257,72.047,72.047,72.047
			c39.79,0,72.047-32.257,72.047-72.047C411.635,346.787,379.378,314.529,339.588,314.529z"
                    />
                  </g>
                </g>
              </svg>
            </div>
          )}
        </label>

        {!readyForSubmit && (
          <div
            className="pasteButton"
            onClick={() => {
              if (!navigator.clipboard) {
                console.error("Clipboard API not supported in this browser");
                return;
              }

              navigator.clipboard
                .readText()
                .then((text) => {
                  // Paste the text into the textarea
                  // document.getElementById("story").value = text;
                  setNewText(text);
                })
                .catch((err) => {
                  console.error("Failed to read clipboard contents: ", err);
                });
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="24"
              height="24"
            >
              <path
                fill="currentColor"
                fill-rule="evenodd"
                d="M16.792 20.14a.994.994 0 001.41 1.4l1.884-1.883a2 2 0 000-2.828l-1.883-1.883a.994.994 0 00-1.411 1.4l.883.897H14.5a1 1 0 100 2h3.175l-.883.897ZM13.737 10h-8V8h8v2Zm-3 4h-5v-2h5v2ZM5 2a2 2 0 00-2 2v16a2 2 0 002 2h7v-2.056c-.02-.099-.013-.198-.006-.298.005-.067.01-.134.006-.202a4.75 4.75 0 01-.002-.333l.002-.167c0-1.666.578-3.083 1.737-4.25 1.159-1.166 2.58-1.75 4.263-1.75.167 0 .333.004.5.012.167.01.333.03.5.063V4a2 2 0 00-2-2H5Zm3.5 16H5.737v-2H8.5v2Z"
                clip-rule="evenodd"
              />
            </svg>
            Paste Text
          </div>
        )}
        <textarea
          id="story"
          name="story"
          rows="9"
          cols="50"
          maxLength={1000}
          placeholder="Type or paste text for correction here..."
          // value={postContent}
          disabled={loading}
          onChange={(e) => updateTextareaSize(e)}
        ></textarea>
        <div className="bottomActionBar">
          {readyForSubmit && readyToCopy && (
            <div
              className={
                textCopied ? "copyTextButton loading" : "copyTextButton"
              }
              onClick={async () => {
                const textarea = document.getElementById("story");
                navigator.clipboard.writeText(textarea.value);
                setTextCopied(true);
                setTimeout(() => {
                  setTextCopied(false);
                }, 1000);
              }}
            >
              {textCopied ? (
                <>
                  <svg
                    width={17}
                    viewBox="0 0 15 11"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{
                      marginBottom: "0px",
                    }}
                  >
                    <path
                      d="M1.13 4.78l4.203 4.204 7.912-7.912"
                      stroke="currentColor"
                      stroke-width="2"
                      fill="none"
                      stroke-linecap="round"
                    ></path>
                  </svg>
                  Text Copied
                </>
              ) : (
                <>
                  <svg
                    height={19}
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M12 3.5C10.8954 3.5 10 4.39543 10 5.5H14C14 4.39543 13.1046 3.5 12 3.5ZM8.53513 3.5C9.22675 2.3044 10.5194 1.5 12 1.5C13.4806 1.5 14.7733 2.3044 15.4649 3.5H17.25C18.9069 3.5 20.25 4.84315 20.25 6.5V18.5C20.25 20.1569 19.1569 21.5 17.25 21.5H6.75C5.09315 21.5 3.75 20.1569 3.75 18.5V6.5C3.75 4.84315 5.09315 3.5 6.75 3.5H8.53513ZM8 5.5H6.75C6.19772 5.5 5.75 5.94772 5.75 6.5V18.5C5.75 19.0523 6.19772 19.5 6.75 19.5H17.25C18.0523 19.5 18.25 19.0523 18.25 18.5V6.5C18.25 5.94772 17.8023 5.5 17.25 5.5H16C16 6.60457 15.1046 7.5 14 7.5H10C8.89543 7.5 8 6.60457 8 5.5Z"
                      fill="currentColor"
                    ></path>
                  </svg>
                  Copy Text
                </>
              )}
            </div>
          )}
          {readyForSubmit && (
            <div
              className={loading ? "submitButton loading" : "submitButton"}
              onClick={async () => {
                if (!loading) {
                  setShowCaptcha(true);
                }
              }}
            >
              {loading ? (
                <>
                  <svg height={19} viewBox="0 0 16 16" fill="none">
                    <path
                      d="M12.784 1.442a.8.8 0 0 0-1.569 0l-.191.953a.8.8 0 0 1-.628.628l-.953.19a.8.8 0 0 0 0 1.57l.953.19a.8.8 0 0 1 .628.629l.19.953a.8.8 0 0 0 1.57 0l.19-.953a.8.8 0 0 1 .629-.628l.953-.19a.8.8 0 0 0 0-1.57l-.953-.19a.8.8 0 0 1-.628-.629l-.19-.953h-.002ZM5.559 4.546a.8.8 0 0 0-1.519 0l-.546 1.64a.8.8 0 0 1-.507.507l-1.64.546a.8.8 0 0 0 0 1.519l1.64.547a.8.8 0 0 1 .507.505l.546 1.641a.8.8 0 0 0 1.519 0l.546-1.64a.8.8 0 0 1 .506-.507l1.641-.546a.8.8 0 0 0 0-1.519l-1.64-.546a.8.8 0 0 1-.507-.506L5.56 4.546Zm5.6 6.4a.8.8 0 0 0-1.519 0l-.147.44a.8.8 0 0 1-.505.507l-.441.146a.8.8 0 0 0 0 1.519l.44.146a.8.8 0 0 1 .507.506l.146.441a.8.8 0 0 0 1.519 0l.147-.44a.8.8 0 0 1 .506-.507l.44-.146a.8.8 0 0 0 0-1.519l-.44-.147a.8.8 0 0 1-.507-.505l-.146-.441Z"
                      fill="currentColor"
                    ></path>
                  </svg>
                  Analyzing...
                </>
              ) : (
                <>
                  <svg height={19} viewBox="0 0 16 16" fill="none">
                    <path
                      d="M12.784 1.442a.8.8 0 0 0-1.569 0l-.191.953a.8.8 0 0 1-.628.628l-.953.19a.8.8 0 0 0 0 1.57l.953.19a.8.8 0 0 1 .628.629l.19.953a.8.8 0 0 0 1.57 0l.19-.953a.8.8 0 0 1 .629-.628l.953-.19a.8.8 0 0 0 0-1.57l-.953-.19a.8.8 0 0 1-.628-.629l-.19-.953h-.002ZM5.559 4.546a.8.8 0 0 0-1.519 0l-.546 1.64a.8.8 0 0 1-.507.507l-1.64.546a.8.8 0 0 0 0 1.519l1.64.547a.8.8 0 0 1 .507.505l.546 1.641a.8.8 0 0 0 1.519 0l.546-1.64a.8.8 0 0 1 .506-.507l1.641-.546a.8.8 0 0 0 0-1.519l-1.64-.546a.8.8 0 0 1-.507-.506L5.56 4.546Zm5.6 6.4a.8.8 0 0 0-1.519 0l-.147.44a.8.8 0 0 1-.505.507l-.441.146a.8.8 0 0 0 0 1.519l.44.146a.8.8 0 0 1 .507.506l.146.441a.8.8 0 0 0 1.519 0l.147-.44a.8.8 0 0 1 .506-.507l.44-.146a.8.8 0 0 0 0-1.519l-.44-.147a.8.8 0 0 1-.507-.505l-.146-.441Z"
                      fill="currentColor"
                    ></path>
                  </svg>
                  Improve Text
                </>
              )}
            </div>
          )}
          <div
            style={{
              display: showCaptcha ? "block" : "none",
              position: "absolute",
              right: "-3px",
              bottom: "-84px",
            }}
          >
            <div className="captchaWrapper">
              <ReCAPTCHA
                ref={reCaptchaRef}
                onChange={onCaptchaEntered}
                sitekey="6Lepq-kZAAAAAGKf0lT5KHuUS3sQDYnuc77NZijB"
              />
            </div>
            <div
              onClick={() => {
                setShowCaptcha(false);
              }}
              className="captchaOverlay"
              aria-hidden="true"
            />
          </div>
        </div>

        <div
          style={{
            display: text === "" ? "none" : "block",
          }}
        >
          <div
            style={{
              margin: "-14px auto 0",
              position: "relative",
              zIndex: 161,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          ></div>
          <div
            onClick={() => {
              setText("");
            }}
            style={{
              background: "rgba(0,0,0,0.8)",
              zIndex: 160,
              position: "fixed",
              top: "0",
              left: "0",
              right: "0",
              bottom: "0",
            }}
          />
          <br />
        </div>
      </div>
    </div>
  );
}

export default App;
